@import '../../../sassStyles/common';
@import '../../../sassStyles/links';

.contact-us-body {
  max-width: 544px;
  max-height: 744px;
  flex-grow: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;
  margin: 0 16px;
}

.contact-us-input {
  padding-top: 24px;
}

.contact-us-title {
  height: 38px;
  align-self: stretch;
  flex-grow: 0;
  font-family: BalooBhai2;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: $primary;
}

.contact-us-message {
  align-self: stretch;
  flex-grow: 0;
  font-family: BalooBhai2;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: $secondary;
}
