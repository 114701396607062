@import '../../../../sassStyles/common';

.emoji-container {
  min-width: 32px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 2px;
  padding: 2px 4px;
  border-radius: 24px;
  background-color: #f0f0f0;
  margin-right: 16px;
}

.pill-emoji__active {
  background-color: #add8e6;
}
