@import '../../../../sassStyles/common';
@import '../../../../sassStyles/fade';

.msg {
  @include fade-in();
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;

  &.msg-info {
    background-color: $msg-info;

    .mb-close-button {
      border: solid 1px $border-info;
    }
  }

  &.msg-warning {
    background-color: $msg-warning;

    .mb-close-button {
      border: solid 1px $border-warning;
    }
  }

  &.msg-error {
    background-color: $msg-error;

    .mb-close-button {
      border: solid 1px $error;
    }
  }

  .msg-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 0;

    .title {
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #23779a;
    }

    .description {
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: $msg-description-color;
    }
  }

  .img {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
    &.msg-info {
      path {
        fill: $msg-info;
      }
    }
    &.msg-warning {
      path {
        fill: $msg-warning;
      }
    }
    &.msg-error {
      path {
        fill: $error;
      }
    }
  }

  .mb-close-button {
    cursor: pointer;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px;
    border-radius: 50px;
    .img {
      &.msg-info {
        path {
          fill: $border-info;
        }
      }
      &.msg-warning {
        path {
          fill: $border-warning;
        }
      }
      &.msg-error {
        path {
          fill: $error;
        }
      }
      width: 16px;
      height: 16px;
      flex-grow: 0;
      object-fit: contain;
    }
  }
}
