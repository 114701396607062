@import '../../../../sassStyles/common';

.post-search-item__container {
  width: 156px;
  height: 263px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 0;
  border-radius: 12px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: $white;
}

.post-search-item__tag {
  position: absolute;
  top: 8px;
  right: 8px;
}

.post-search-item__image {
  width: 156px;
  height: 156px;
  border-radius: 12px 12px 0 0;
  object-fit: cover;
}

.post-search-item__info {
  display: flex;
  flex-direction: column;
  width: 132px;
  padding: 12px;
  gap: 8px;
}

.post-search-item__carbon-footprint {
  display: inline-flex;
  gap: 8px;
  width: 100%;
}

.post-search-item__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}

.post-search-item__price {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.save-icon {
  height: 24px;
  width: 24px;
  margin-left: auto;
}

.leaf-icon {
  height: 16px;
  width: 16px;
}

.post-search-item__carbon-footprint {
  font-size: 14px;
  align-items: center;
  font-weight: normal;
}

@media (min-width: 435px) {
  .post-search-item__container {
    width: 320px;
    height: 424px;
  }

  .post-search-item__tag {
    top: 12px;
    right: 12px;
  }

  .post-search-item__image {
    width: 320px;
    height: 320px;
  }

  .post-search-item__info {
    width: 296px;
    padding: 12px;
  }

  .post-search-item__price {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}
