@import '../../../../sassStyles/common';

.mobile-modal-menu__dropdown {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  border-radius: 16px 16px 0 0;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  background-color: white;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);

  &.open {
    transform: translateY(0);
  }

  .mobile-modal-menu__dropdown-content {
    padding: 16px;
    border-radius: 16px 0;
    margin-top: 20px;
  }

  .mobile-modal-menu__top-line {
    position: absolute;
    top: 8px;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 4px;
    background-color: $disabled;
    border-radius: 2px;
  }

  .mobile-modal-menu__dropdown-exit {
    position: absolute;
    top: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: none;
  }
}

@media screen and (min-width: 768px) {
  .mobile-modal-menu__dropdown {
    top: 0;
  }
}
