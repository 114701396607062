@import '../../../../sassStyles/common';

.post-info__description {
  margin-top: 4px;
  font-size: 14px;
  line-height: 1.14;
  color: $black;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-info__description.full-text {
  -webkit-line-clamp: unset;
}
.post-info__description.see-more {
  margin-top: 0;
}
