@import '../../../../sassStyles/common';
@import '../../../../sassStyles/fade';

.snackbar-container {
    @include fade-in();
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    margin: 48px 0 0 8px;
    padding: 12px 16px;
    border-radius: 32px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.12);
    align-self: center;
    z-index: 10;

    &.hidden {
        display: none;
    }

    .text-container {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 0;
        object-fit: contain;

        .icon {
            width: 24px;
            height: 24px;
            flex-grow: 0;
            object-fit: contain;
    
            &.info {
                color: $border-info;
            }
        
            &.warning {
                color: $border-warning;
            }
        
            &.success {
                color: $border-success;
            }
        
            &.error {
                color: $border-error;
            }
        }
    
        .title {
            flex-grow: 0;
            font-family: Baloo Bhai 2;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
    
            &.info {
                color: $border-info;
            }
        
            &.warning {
                color: $border-warning;
            }
        
            &.success {
                color: $border-success;
            }
        
            &.error {
                color: $border-error;
            }
        }
    }

    .link-container {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 2px;
        border-radius: 4px;
    }

    &.info {
        border: solid 2px $border-info;
        background-color: $msg-info;
    }

    &.warning {
        border: solid 2px $border-warning;
        background-color: $msg-warning;
    }

    &.success {
        border: solid 2px $border-success;
        background-color: $msg-correct;
    }

    &.error {
        border: solid 2px $border-error;
        background-color: $msg-error;
    }

    
}