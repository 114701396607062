@import '../../../../sassStyles/common';

@mixin validation-type($color) {
    .txt {
        color: $color;
    }
    .img {
        path {
            stroke: $color;
        }
    }
}

.validation-status-item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 0;

    &.info {
        @include validation-type($black);
    }

    &.correct {
        @include validation-type($msg-correct);
    }

    &.error {
        @include validation-type($error);
    }


    .img {
        width: 24px;
        height: 24px;
        flex-grow: 0;
        object-fit: contain;
    }

    .txt {
        flex-grow: 1;
        font-family: Baloo Bhai 2;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
    }
}