@import '../../../../sassStyles/common';

.settings-container {
  display: flex;
  max-width: 910px;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 125px;
  margin: 62px auto 0;

  .settings__navigate-back {
    display: inline-flex;
    align-self: flex-start;
    gap: 4px;
    font-size: 14px;
    font-weight: bold;
    color: $secondary;
    text-decoration: underline;
  }

  .avatar-settings-container {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    align-self: center;
  }

  .data-container {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    padding: 0;

    &.nick {
      margin-top: 32px;
      margin-bottom: 15px;
    }

    &.email {
      margin-top: 22px;
      margin-bottom: 15px;
    }

    &.password {
      margin-top: 22px;
      margin-bottom: 15px;
    }

    .title {
      height: 24px;
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $counter;
    }

    .nick {
      height: 20px;
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: $black;
    }

    .email {
      height: 22px;
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $black;
    }

    .password {
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $black;

      .bold {
        font-weight: 600;
        line-height: 1.38;
      }
    }
  }

  .line-separator-container {
    display: flex;
    width: 100%;
  }

  .remove-account-container {
    margin-top: 6.7%;
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  > .title {
    margin-top: 59px;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
  }
}

.remove-account-message-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  align-self: center;
  justify-content: flex-start;
  justify-self: center;
  align-items: flex-start;
  gap: 24px;
  margin: 0 32px;

  .options-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-grow: 0;
  }

  .text-container {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    padding: 0;

    .title {
      height: 28px;
      flex-grow: 0;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: $primary;
    }

    .description {
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $black;
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 435px) {
  .settings-container {
    margin: 10px 16px 0;

    .title {
      margin-top: unset;
    }

    .settings__navigate-back {
      display: none;
    }
  }
}
