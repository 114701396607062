@import '../../../../sassStyles/common';

.Login-container {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  align-self: center;
  margin: 10% 16px;
}

.Login-credentials {
  margin-top: 11.4%;
  display: flex;
  flex-direction: column;
}

.Login-continue {
  margin-top: 40px;
  display: flex;
}

.Login-register-container {
  margin-top: 3.1%;
  display: flex;
  justify-content: center;
}

.Login-forgiven-password-container {
  display: flex;
  justify-content: flex-start;
}

.Login-email-container {
  margin: 0 0 10.1% 0;
}

.Login-password-container {
  margin: 0 0 6.4% 0;
}

.Login-title {
  height: 4%;
  align-self: stretch;
  flex-grow: 1;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: $primary;
}

@media (max-width: 435px) {
  .Login-container {
    max-width: none;
    padding: 0 16px;
  }

  .bottom-container {
    margin-top: auto;
  }
}
