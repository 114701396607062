@import '../../../sassStyles/common';

.profile__container {
  display: flex;
  flex-direction: column;
  max-width: 328px;
  margin: 0 auto;
}
@media (min-width: 435px) {
  .profile__container {
    align-self: auto;
    width: 1024px;
    max-width: unset;
    margin: 42px auto 0;
  }
}
