@import '../../../../sassStyles/common';

.selling-post-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;

  > .icon {
    width: 64px;
    height: 64px;
    align-self: center;
  }

  .title {
    margin-top: 12px;
    font-family: Baloo Bhai 2;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
  }

  .description {
    margin-top: 1px;
    font-family: Baloo Bhai 2;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $counter;
  }

  .search-user-container {
    margin-top: 16px;
  }

  .name-found-title {
    margin-top: 16px;
    font-family: Baloo Bhai 2;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }

  .user-found-container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;

    .text {
      font-family: Baloo Bhai 2;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: $black;
    }

    .change-container {
      justify-self: flex-end;
      flex-grow: 0;
    }
  }

  .confirm-deal-container {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    margin-top: 75px;
    padding: 0;

    .title {
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $primary;
    }

    .points-container {
      height: 80px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      border-radius: 16px;
      padding: 12px 0;
      background-color: $card-background-selected;

      .title {
        height: 24px;
        align-self: stretch;
        flex-grow: 0;
        font-family: Baloo Bhai 2;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: $secondary;

        .bold {
          font-weight: bold;
          line-height: 1.25;
        }
      }

      .points {
        height: 32px;
        align-self: stretch;
        flex-grow: 0;
        font-family: Baloo Bhai 2;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $secondary;
      }
    }

    .description {
      height: 44px;
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: $black;

      .bold {
        font-weight: 600;
      }
    }
  }

  .result-container {
    height: 50%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .user-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 8px;
      margin-top: 28px;

      .text {
        font-family: Baloo Bhai 2;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: $black;
      }
    }
  }

  .no-result-found-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .icon {
      width: 120px;
      height: 120px;
      opacity: 0.4;
    }
    .text {
      height: 44px;
      margin-top: 16px;
      font-family: Baloo Bhai 2;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: $black;
    }
  }

  .confirm-deal-button-container {
    margin-top: 24px;
    align-self: center;
    width: 100%;
  }
}

@media (min-width: 435px) {
  .selling-post-container {
    margin-top: 125px;
    margin-left: 40%;
    max-width: 437px;
    flex-grow: 1;
  }
}
