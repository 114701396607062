@import '../../../../../sassStyles/common';

.nav-tab__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-bottom: 1px solid $counter;
}

.nav-tab__item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.nav-tab__active {
  border-bottom: 3px solid $active;
  color: $primary;
}
