.zircle-info-card__container {
  display: grid;
  width: 507px;
  height: 192px;
  padding: 0 24px;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  border-radius: 16px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.zircle-info-card__title {
  grid-column: 2 / span 3;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  align-self: flex-end;
  text-align: left;
  color: #111;
}

.zircle-info-card__avatar {
  grid-column: 1 / span 1;
  grid-row: 1 / -1;
}

.zircle-info-card__item {
  grid-row: 2 / span 1;
  align-self: flex-start;
}

.zircle-info-card__info {
  grid-column: 3 / span 2;
  grid-row: 2 / span 1;
  line-height: 1.09;
  font-size: 22px;
  font-weight: 600;
}

.zircle-info-card__info-label {
  font-size: 14px;
  line-height: 1;
}

@media (max-width: 435px) {
  .zircle-info-card__container {
    justify-self: center;
    max-width: 288px;
    height: 120px;
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    padding: 0 20px;
  }

  .zircle-info-card__title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
  }

  .zircle-info-card__item {
    font-size: 14px;
    line-height: 1;
  }

  .zircle-info-card__info {
    font-size: 16px;
    font-weight: bold;
  }
}
