@import '../../../../sassStyles/common';

.join-zircle__icon {
  display: flex;
  justify-content: center;
}

.join-zircle-container {
  background-color: $white;
  max-width: 320px;
  align-self: center;
  margin: 149px 16px 0;
}

.join-zircle-input {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 24px;
  gap: 16px;
}

.join-zircle-title {
  align-self: stretch;
  flex-grow: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.19;
  text-align: center;
  color: $primary;
}

.join-zircle-message {
  flex-grow: 0;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: $black;
  &.secondary {
    color: $secondary;
  }
}

.join-zircle__step-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 4px;
}

.join-zircle__center-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.join-zircle__bottom-container {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.join-zircle__second-step__privacy-text {
  display: inline-flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
}

.join-zircle__third-step__mail {
  display: inline-flex;
  align-self: center;
}

@media (max-width: 435px) {
  .join-zircle-container {
    height: calc(100vh - 64px);
    margin: 10px auto 0;
  }

  .join-zircle__third-step-icon {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .join-zircle__bottom-container {
    margin-top: auto;
    margin-bottom: 32px;
  }
}
