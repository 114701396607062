@import '../../../../sassStyles/common';

// Define a mixin for the underscore effect
@mixin underscore-effect($color) {
  // Use text-decoration to remove the default underline
  text-decoration-line: underline;

  // Use transition to animate the border-bottom on hover and active states
  transition: text-decoration-thickness 0.3s ease;

  // Change the border-bottom on hover to create a thicker line
  &:hover {
    text-decoration-thickness: 2px;
  }

  // Change the border-bottom on active to create a dotted line
  &:active {
    text-decoration-thickness: 1px;
  }
  cursor: pointer;
}

.link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  gap: 4px;
  border-radius: 4px;

  .icon {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;

    &.link-primary {
      color: $primary;
      fill: $primary;
      stroke: $primary;
      svg {
        fill: $primary;
        stroke: $primary;
      }
    }

    &.link-secondary {
      color: $secondary;
      fill: $secondary;
      stroke: $secondary;
      svg {
        fill: $secondary;
        stroke: $secondary;
      }
    }

    &.link-primary-alt {
      color: $white;
      svg {
        fill: $white;
        stroke: $white;
      }
    }

    &.link-error {
      color: $error;
      svg {
        fill: $error;
        stroke: $error;
      }
    }

    &:disabled {
      color: $disabled;
      svg {
        * {
          fill: $disabled;
          stroke: $disabled;
        }
      }
    }
  }

  .label {
    font-family: Baloo Bhai 2;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

    &.link-primary {
      color: $primary;
      @include underscore-effect($primary);
    }

    &.link-secondary {
      color: $secondary;
      @include underscore-effect($secondary);
    }

    &.link-primary-alt {
      color: $white;
      @include underscore-effect($white);
    }

    &.link-error {
      color: $error;
      @include underscore-effect($error);
    }

    &.disabled {
      color: $disabled;
    }
  }

  .disabled {
    pointer-events: none;
  }
}
