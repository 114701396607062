@import '../../../../sassStyles/common';

// Define a mixin for the material-like effect
@mixin material-effect($color) {
  cursor: pointer;
  // Use box-shadow to create a subtle shadow around the div
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  // Use transition to animate the box-shadow on hover and active states
  transition: box-shadow 0.3s ease;

  // Use a pseudo-element to create a ripple effect on click
  position: relative;
  overflow: hidden;

  &::after {
    // Position the pseudo-element over the div
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // Use pointer-events to prevent the pseudo-element from interfering with clicks
    pointer-events: none;

    // Use transform and opacity to hide the pseudo-element by default
    transform: scale(0);
    opacity: 0;

    // Use background-color to set the ripple color
    background-color: $color;

    // Use border-radius to make the ripple circular
    border-radius: 50%;

    // Use transition to animate the transform and opacity on click
    transition:
      transform 0.5s ease,
      opacity 0.5s ease;
  }

  // Change the box-shadow on hover to create a lifted effect
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  // Change the box-shadow on active to create a pressed effect
  &:active {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 24px;

  &.lg {
    height: 24px;
    flex-grow: 1;
  }
  &.sm {
    height: 20px;
    padding: 8px 20px;
  }

  &.btn-primary {
    background-color: $btn-primary;
    @include material-effect($primary);
  }

  &.btn-secondary {
    border: solid 2px $primary;
    @include material-effect($primary);
  }

  &.btn-error {
    border: solid 2px $error;
    @include material-effect($error);
  }

  &.btn-primary-alt {
    background-color: $white;
    @include material-effect($white);
  }

  &.disabled {
    background-color: $disabled;
  }

  .icon {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
  }

  .label {
    flex-grow: 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    align-self: center;
    color: $primary;
    cursor: pointer;

    &.disabled {
      cursor: initial;
      color: $disabled;
    }
  }
}
