@import '../../../../sassStyles/common';

.radio-button {
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 0;

    .button {
        width: 24px;
        height: 24px;
        flex-grow: 0;
        border-radius: 100px;
        border: solid 1px $secondary;
        background-color: $white;

        &.disabled {
            border: solid 1px $disabled;
            background-color: $disabled;
        }

        &.selected .disabled {
            border: solid 2px $disabled;
            background-color: $disabled;
            .fill {
                background-color: $disabled;
            }
        }

        &.selected {
            border: solid 2px $primary;
            background-color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .fill {
                width: 12px;
                height: 12px;
                border-radius: 100px;
                background-color: $primary;
            }
        }
    }
    label {
        height: 24px;
        flex-grow: 0;
        font-family: Baloo Bhai 2;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $black;

        &.disabled {
            color: $disabled;
        }
    }
}