@import '../../../../sassStyles/common';

.custom-checkbox input[type='checkbox'] {
  display: none;
}

.custom-checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 10px;
}

.custom-checkbox label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid $disabled;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.custom-checkbox input[type='checkbox']:checked + label::before {
  border-color: $primary;
  background-color: $primary;
}

.custom-checkbox label::after {
  content: '';
  width: 6px;
  height: 12px;
  border: 2px solid white;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 2px;
  left: 7px;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-checkbox input[type='checkbox']:checked + label::after {
  opacity: 1;
}

.custom-checkbox label span {
  vertical-align: middle;
}
