@import '../../../../sassStyles/common';

.input-comment {
  height: 72px;
  width: 328px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
  background-color: $white;

  //TODO: This could be secondary colors for input borders
  .Input-body .Input-container {
    border: solid 1px $disabled;

    &:focus-within {
      border: solid 2px $secondary;
    }

    input:focus {
      caret-color: $secondary;
    }
  }
}

@media (min-width: 435px) {
  .input-comment {
    margin: 40px 0;
  }
}
