@import '../../../../../../sassStyles/common';

.desktop-menu__container {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1;
  height: calc(100vh - 96px);
  width: 267px;
  padding: 48px 16px;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
}

.desktop-menu__top {
  display: flex;
  height: 48px;
  margin-bottom: 48px;
  gap: 8px;
}

.desktop-menu__title {
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 38.4px;
  font-weight: bold;
  color: $primary;
  span {
    height: 40px;
  }
}
.desktop-menu__subtitle {
  font-size: 11.2px;
  font-weight: 500;
  text-align: left;
  color: $primary;
}

.desktop-menu__sections {
  display: flex;
  flex-direction: column;
  color: $primary;
  font-weight: 600;
}

.desktop-menu__section-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  height: 56px;
  gap: 8px;
  padding: 0 16px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #c2f3ec;
  }
  &.desktop-menu__active {
    background-color: #c2f3ec;
  }
}

.desktop-menu__log-out {
  display: flex;
  margin-top: auto;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

@media (max-width: 435px) {
  .desktop-menu__container {
    display: none;
  }
}
