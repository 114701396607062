@import '../../../../sassStyles/common';

.avatar-container {
  height: 56px;
  width: 56px;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: $primary; //check on this background when image is present

  img {
    border-radius: 100px;
    object-fit: cover;
  }
}
