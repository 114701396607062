@import '../../../../sassStyles/common';

.card-list {
  flex-grow: 1;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
