@import '../../../../sassStyles/common';

.line-separator {
    height: 1px;
    flex-grow: 1;

    &.light {
        background: $disabled;
    }

    &.dark {
        background: $disabled;
    }
    
}