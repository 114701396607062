@import '../../../../sassStyles/common';

.post-header__container {
  height: 74px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  background-color: $white;
}

.post-header__user-data {
  display: flex;
  align-items: center;
  gap: 8px;
}

.post-header__user-contact {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 1.14;
  color: $disabled;
  gap: 4px;
}

.post-header__chat-button {
  display: flex;
  align-items: center;
  gap: 4px;
}

@media (max-width: 435px) {
  .post-header__user-data {
    flex: 1;
    margin-right: 4px;
    overflow: hidden;
  }
}
