@import '../../../../sassStyles/common';

.co2-details__title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: $primary;
  margin-bottom: 8px;
}

.co2-details__body {
  font-size: 18px;
  line-height: 1.22;
  color: $black;
  margin-bottom: 8px;
}

@media (min-width: 435px) {
  .co2-details__container {
    height: auto;
    max-width: 586px;
  }
}
