@import '../../../../../sassStyles/common';

.confirmation-menu__title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: $primary;
}

.confirmation-menu__body {
  font-size: 18px;
  line-height: 1.22;
  color: $black;
}

.confirmation-menu__buttons-row {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 435px) {
  .confirmation-menu__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .confirmation-menu__body {
    font-size: 16px;
  }
}
