@import '../../../../../sassStyles/common';

.post-list__separator {
  width: 100%;
  height: 1px;
  flex-grow: 0;
  margin: 32px 0 24px 0;
  transform: rotate(-360deg);
  background-color: $disabled;
}

.post-list__content-row {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: flex-start;
}

.post-list__empty-message {
  width: 437px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: $counter;
  gap: 22px;
  margin: auto;
}

.post-list__empty-message-title {
  font-weight: 600;
}
.post-list__empty-message-text {
  text-align: center;
}

@media (max-width: 435px) {
  .post-list__empty-message {
    width: auto;
  }
}
