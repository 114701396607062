@import '../../../../sassStyles/common';

.popover-container {
  position: relative;
  display: inline-block;
}

.popover-content {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  border-radius: 16px;
  padding: 8px 16px;
  background-color: $white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
  min-width: 150px;
  display: none;
}

.popover-container .popover-content {
  display: block;
}
