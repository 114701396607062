// Define a custom animation for fading in and out
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

// Apply the animation to the component with the fade mixin
@mixin fade-in() {
    animation: fade-in 0.5s ease-in;
}

@mixin fade-out() {
    animation: fade-out 0.5s ease-out;
}