@import '../../../../sassStyles/common';

.tag-container {
  width: 66px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: solid 1px #444;
  background-color: #e6e6e6;

  font-size: 12px;
  font-weight: 600;
  line-height: 1.17;
  color: $black;

  &.gift {
    border: solid 1px #de5000;
    background-color: #fff1ce;
    color: #de5000;
  }
  &.rent {
    border: solid 1px #1a28a6;
    background-color: #c4caf5;
    color: #101e96;
  }
  &.sell {
    border: solid 1px $secondary;
    background-color: #c2f3ec;
    color: $secondary;
  }
  &.share {
    border: solid 1px #0073de;
    background-color: #cef3ff;
    color: #0073de;
  }
}
