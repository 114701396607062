.zircle-users-list__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  height: calc(100vh - 168px);
  max-width: 328px;
  gap: 16px;
  margin: 24px auto;
}

.zircle-users-list__users-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 16px;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #c2f3ec;
  }

  &::-webkit-scrollbar-thumb {
    background: #17ae99;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #169a89;
  }
}

.zircle-users-list__user-row {
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  height: 40px;
}

@media (min-width: 435px) {
  .zircle-users-list__container {
    width: 30vw;
    max-width: none;
    height: 70vh;
    margin: 0;
  }
}
