@import '../../../../sassStyles/common';

.add-image {
  width: 104px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  border-radius: 24px;
  border: solid 2px $counter;
  background-color: $add-image-background;

  .text-container {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 0;

    img {
      width: 24px;
      height: 24px;
      flex-grow: 0;
      object-fit: contain;
    }

    .title {
      height: 42px;
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: $counter;
    }
  }

  &.filled {
    justify-content: flex-start;
    align-items: flex-end;
    border-radius: 24px;
    border: solid 2px $btn-primary;

    .delete-image {
      flex-grow: 0;
      padding: 4px;
      border-radius: 24px;
      background-color: $white;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }
}

@media (min-width: 435px) {
  .add-image {
    width: 152px;
    height: 166px;
  }
}
