@import '../../../../sassStyles/common';

.co2-card__container {
  display: grid;
  position: relative;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr minmax(0, 381px);
  column-gap: 18px;
  width: 381px;
  height: 148px;
  padding: 32px 32px 12px;
  align-items: center;
  background-color: #c2f3ec;
  border-radius: 16px;

  button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: $white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .prev-button,
  .next-button {
    width: 24px;
    height: 24px;
    padding: 4px;
    opacity: 0.8;
    background-color: $secondary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $white;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }

  .prev-button {
    left: -12px;
  }

  .next-button {
    right: -12px;
  }
}

.co2-card__level-1 {
  background-color: #c2f3ec;
}

.co2-card__level-2 {
  background-color: #8ff3e5;
}

.co2-card__level-3 {
  background-color: #6df1de;
}

.co2-card__level-4 {
  background-color: #34f2d7;
}

.co2-card__level-5 {
  background-color: #30dec5;
}

.co2-card__level-badge {
  flex-direction: column;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  gap: 2px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: $secondary;
}

.co2-card__level-badge-text {
  font-size: 9px;
  font-weight: 600;
  color: $white;
}

.co2-card__illustration-label {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  line-height: 1;

  &.light {
    color: #fff;
  }

  &.dark {
    color: #004c51;
  }
}

.co2-card__label-start,
.co2-card__label-end {
  display: flex;
  font-size: 14px;
}

.co2-card__label-main {
  display: flex;
  font-size: 24px;
  font-weight: 600;
}

.co2-card__title {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.19;
  text-align: left;
  color: $secondary;
}

.co2-card__subtitle {
  grid-column: 2 / 4;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $secondary;
}

.co2-card__level-subtitle {
  grid-column: 1 / -1;
  align-self: flex-start;
  font-size: 12px;
  line-height: 1.17;
  color: $secondary;
}

.co2-card__level-row {
  display: flex;
  grid-column: 1 / -1;
  grid-row: 2 / span 1;
}

.co2-card__level-item {
  width: 20%;
  height: 4px;
  margin: 0 4px 0 0;
  background-color: #ffffff;
  &.completed {
    background-color: $secondary;
  }
  &.next {
    background-color: #27c6af;
  }
}

.co2-card__avatar {
  grid-column: 1 / span 1;
  grid-row: 1 / -1;
}

.co2-card__item {
  grid-row: 2 / span 1;
}

.co2-card__info {
  grid-column: 3 / span 2;
  grid-row: 2 / span 1;
}

.co2-card__info {
  font-size: 22px;
  font-weight: 600;
}

@media (max-width: 435px) {
  .co2-card__container {
    justify-self: center;
    max-width: 288px;
    height: 152px;
    padding: 20px;
  }
}
