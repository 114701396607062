@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;600;700&display=swap');

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family:
    'Baloo Bhai 2',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: 'Baloo Bhai 2';
}

input:-internal-edge-excel-like-autofill-previewed {
  font-family: 'Baloo Bhai 2';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.screen-container {
  min-height: calc(100% - 88px);
  display: flex;
  flex-direction: column;
  padding-bottom: 88px;
}

.screen-container.landing {
  height: 100%;
  padding-bottom: 0;
}

@media (min-width: 435px) {
  .screen-container {
    flex-direction: row;
    padding-bottom: 0;
  }
  .screen-container.landing {
    flex-direction: row-reverse;
  }
}
