@import '../../../../../sassStyles/common';

.card {
  height: 43px;
  width: 292px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 24px;
  border: solid 1px $secondary;
  background-color: $white;

  &.selected {
    height: 41px;
    border: solid 2px $card-border-selected;
    background-color: $card-background-selected;
  }

  .card-illustration {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: $active;
    border-radius: 12px;
  }

  .illustration {
    width: 28px;
    height: 28px;
  }

  .text-container {
    flex-grow: 1;
    display: flex;
    gap: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-right: 12px;
    padding: 0;

    .title {
      height: 22px;
      align-self: stretch;
      flex-grow: 0;
      font-family: Baloo Bhai 2;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: $black;
    }
    .link {
      align-self: flex-start;
      flex-grow: 0;
    }
  }
}

@media (min-width: 435px) {
  .card {
    height: 46px;
    width: 640px;

    &.selected {
      height: 44px;
    }
  }
}
