@import '../../../sassStyles/common';

.detail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  margin: 0 16px;
  min-height: calc(100vh - 64px);
  max-width: 328px;
}

.post-detail__navigate-back {
  display: none;
}

.uploaded-post-confirmation-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  align-self: center;
  justify-content: center;
  justify-self: center;
  align-items: center;
  margin: 0 32px;
}

.info-row {
  display: flex;
  align-items: center;
  flex-grow: 0;
  gap: 8px;
}
.info-price {
  font-size: 26px;
  font-weight: 600;
  color: #19191b;
}
.info-co2 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;
}

.info-actions {
  display: flex;
  gap: 16px;
  margin-left: auto;
}

.info-icon {
  width: 24px;
  height: 24px;

  object-fit: contain;
}

.title-row {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.09;
  margin-top: 12px;
  color: $black;
}

.emoji-picker-row {
  margin-bottom: 8px;
}

.reactions-row {
  display: flex;
  margin: 8px 0;
}

.emoji-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.separator {
  width: 100%;
  height: 1px;
  flex-grow: 0;
  margin: 12px 0 32px 0;
  transform: rotate(-360deg);
  background-color: $disabled;
}

.post-detail__comments {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.no-comment-message {
  display: inline-flex;
  align-self: flex-start;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $counter;
}

.desktop-split {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.post-detail__content-row {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: flex-start;
}

.post-detail__date-row {
  font-size: 12px;
  margin-top: 12px;
  color: $counter;
  line-height: 1.17;
}

@media (min-width: 435px) {
  .detail-container {
    max-width: 910px;
    margin: 0 auto 0 310px;
  }
  .desktop-split {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
  }

  .images-row {
    flex: 1;
  }

  .post-detail__content-row {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: flex-start;
  }

  .post-detail__navigate-back {
    display: inline-flex;
    gap: 4px;
    font-size: 14px;
    font-weight: bold;
    color: $secondary;
    text-decoration: underline;
    margin-bottom: 18px;
  }

  .post-detail__comments {
    height: 235px;
    overflow: auto;
  }
}
