@import '../../../../sassStyles/common';

@mixin container() {
  background-color: $white;
  max-width: 320px;
  align-self: center;
  margin: 149px 16px 0;
}

.forgiven-password__icon {
  display: flex;
  justify-content: center;

  img {
    width: 80px;
    height: 80px;
  }
}

.forgiven-password-container {
  @include container();
}

.forgiven-password-confirmation-container {
  @include container();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.forgiven-password-confirmation-message {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  img {
    width: 80px;
    height: 80px;
    flex-grow: 0;
    object-fit: contain;
  }

  .title {
    align-self: stretch;
    flex-grow: 0;
    font-family: Baloo Bhai 2;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: $primary;
  }

  .description {
    align-self: stretch;
    flex-grow: 0;
    font-family: Baloo Bhai 2;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: $black;

    .bold {
      font-weight: 600;
    }
  }
}

.forgiven-password-confirmation-button {
  margin-top: 48px;
}

.forgiven-password-input {
  padding-top: 24px;
}

.forgiven-password-title {
  align-self: stretch;
  flex-grow: 0;
  font-size: 32px;
  margin-top: 24px;
  font-weight: bold;
  line-height: 1.19;
  text-align: center;
  color: $primary;
}

.forgiven-password-message {
  flex-grow: 0;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: $black;
  &.secondary {
    color: $secondary;
  }
}

.forgiven-password__step-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 4px;
}

.center-container {
  display: flex;
  justify-content: center;
}

.forgiven-password__bottom-container {
  margin-top: 48px;
}

.forgiven-password__second-step__privacy-text {
  display: inline-flex;
}

.forgiven-password__second-step__mail {
  display: inline-flex;
  align-self: center;
}

@media (max-width: 435px) {
  .forgiven-password__icon {
  
    img {
      width: 52px;
      height: 52px;
    }
  }

  .forgiven-password-container {
    height: 100%;
  }

  .forgiven-password-confirmation-container {
    background-color: $primary;
    padding-top: 30%;
    min-height: 100vh;
    max-width: none;
    margin: 0;
  }

  .forgiven-password-confirmation-message {
    .title {
      color: $white;
    }
    .description {
      color: $white;
    }
  }

  .forgiven-password-confirmation-button {
    justify-self: end;
  }

  .forgiven-password__third-step-icon {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .forgiven-password__bottom-container {
    margin-top: auto;
    margin-bottom: 32px;
  }
}
