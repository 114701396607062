@import '../../../../sassStyles/common';

.carousel {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  align-self: center;
  overflow: hidden;
  width: 328px;
  height: 328px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
  }

  button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: $white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .prev-button,
  .next-button {
    width: 24px;
    height: 24px;
    padding: 4px;
    opacity: 0.8;
    background-color: $white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }

  .prev-button {
    left: 10px;
  }

  .next-button {
    right: 10px;
  }

  .carousel-tag {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .carousel-items {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
}

@media (min-width: 435px) {
  .carousel {
    width: 437px;
    height: 437px;
  }
}
