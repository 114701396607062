@import '../../../../../../sassStyles/common';

.comment-container {
  display: flex;
  gap: 8px;
}

.comment-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.comment-username {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
}

.comment-text {
  font-size: 14px;
  line-height: 1.14;
  color: $counter;
}
