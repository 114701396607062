.zircle-cards__container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 16px;
  margin: 10px 0 24px 0;
}

@media (min-width: 435px) {
  .zircle-cards__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: none;
    grid-column-gap: 24px;
    grid-row-gap: 0;
  }
}
