@import '../../../../sassStyles/common';

.mobile-header__container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px 0;
  background-color: $primary;
  flex-grow: 0;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: $white;
}

.mobile-header__navigate-back {
  position: absolute;
  left: 16px;
  display: flex;
}

.mobile-header__navigate-out {
  position: absolute;
  display: flex;
  right: 16px;
  font-size: 14px;
  text-decoration: underline;
}

.mobile-header__log-out {
  position: absolute;
  right: 16px;
  display: flex;
}

@media (min-width: 435px) {
  .mobile-header__container {
    display: none;
  }
}
