$white: #fff;
$black: #111;
$primary: #3849da;
$secondary: #004c51;
$active: #34f2d7;
$error: #cc0000;
$disabled: #ccc;
$counter: #676767;
$btn-primary: #34f2d7;
$msg-info: #e8f8f9;
$msg-warning: #fffaeb;
$msg-error: #fee5e5;
$msg-correct: #f0f8f0;
$border-info: #23779a;
$border-warning: #856300;
$border-success: #008035;
$border-error: #c00;
$msg-description-color: #111111;
$card-border-selected: #17ae99;
$card-background-selected: #c2f3ec;
$add-image-background: #f0f0f0;

img.Close {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
}

.hidden {
  display: none;
}
