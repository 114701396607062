@import '../../../../sassStyles/common';

.adaptive-dialog__container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.adaptive-dialog__content {
  background-color: white;
  padding: 32px;
  border-radius: 24px;
  margin: auto auto;
}

.adaptive-dialog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.adaptive-dialog__close {
  cursor: pointer;
  justify-self: flex-end;
}

.adaptive-dialog__title {
  font-size: 24px;
  font-weight: 600;
  color: $primary;
  margin: 0;
}

@media (max-width: 435px) {
  .adaptive-dialog__container {
    top: 64px;
  }

  .adaptive-dialog__content {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 0;
    flex-grow: 1;
    height: 100%;
    margin: 0;
  }

  .adaptive-dialog__header {
    padding: 0 16px;
  }
}
