.profile-card__container {
  position: relative;
  display: grid;
  width: 507px;
  height: 192px;
  padding: 0 24px;
  align-items: center;
  grid-template-columns: 0.5fr 1fr 1fr 0.2fr;
  grid-template-rows: 0.8fr 0.3fr 0.6fr;
  grid-column-gap: 24px;
  border-radius: 16px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.profile-card__settings {
  position: absolute;
  top: 24px;
  right: 24px;
}

.profile-card__title {
  grid-column: 2 / span 3;
  font-size: 42px;
  font-weight: bold;
  line-height: 1.14;
  align-self: flex-end;
  text-align: left;
  color: #111;
}

.profile-card__avatar {
  grid-column: 1 / span 1;
  grid-row: 1 / -1;
}

.profile-card__item {
  display: flex;
  grid-column: span 3;
  align-self: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 18px;
  overflow: hidden;
}

.profile-card__info {
  grid-column: 3 / span 2;
  grid-row: 2 / span 1;
  font-weight: 600;
  white-space: nowrap;
}

@media (max-width: 435px) {
  .profile-card__container {
    justify-self: center;
    max-width: 288px;
    height: 120px;
    grid-template-rows: 1fr 0.3fr 0.6fr;
    grid-column-gap: 16px;
    grid-row-gap: 4px;
    padding: 0 20px;
  }

  .profile-card__settings {
    right: 16px;
  }

  .profile-card__title {
    grid-column: 2 / span 2;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.17;
    white-space: nowrap;
    overflow: hidden;
  }

  .profile-card__item {
    font-size: 16px;
    line-height: 1;
  }

  .profile-card__info {
    font-size: 14px;
  }
}
