@import '../../../../sassStyles/common';

.adaptive-sidenav__background {
  background-image: url('../../../../img/background/mountain-lake.jpg');
  background-size: cover;
  width: 100vw;
  // background-position: center;
}

.adaptive-sidenav__logo {
  position: absolute;
  left: 32px;
  top: 32px;
}

.adaptive-sidenav__container {
  width: 587px;
  height: 100vh;
  margin-left: auto;
  background-color: $white;
  border-radius: 52px 0 0 0;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.adaptive-sidenav__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Rectangle-27,
.open,
.close {
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 435px) {
  .adaptive-sidenav__logo {
    display: none;
  }

  .adaptive-sidenav__container {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .adaptive-sidenav__content {
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    flex-grow: 1;
  }
  .adaptive-sidenav__background {
    background-image: none;
    height: calc(100% - 80px);
  }
}
