@import '../../../sassStyles/common';

.search-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 28px);
  max-width: 328px;
  margin: 14px auto 0;
  gap: 16px;
}

.post-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.search-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  margin: 120px auto;
}

.new-search-message {
  font-size: 18px;
  line-height: 1.22;
  color: $counter;
}

@media (min-width: 435px) {
  .search-container {
    width: 1024px;
    max-width: unset;
    margin: 44px auto 0;
    padding: 0;
  }

  .post-list {
    justify-content: flex-start;
    gap: 32px;
  }

  .search-message {
    margin: auto;
  }
}
